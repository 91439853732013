import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import ReactSelect, {
  ClearIndicatorProps,
  ControlProps,
  IndicatorsContainerProps,
  InputProps as ReactSelectInputProps,
  MenuProps,
  OptionProps,
  PlaceholderProps,
  components,
  OnChangeValue,
  ValueContainerProps
} from 'react-select';
import { tryPluralizeWord } from '@trustblock/helpers/formatters';
import Icon, { IconName } from '../../Icon/Icon';
import inputStyles from '../Inputs.module.scss';
import { InputProps } from '../input.types';
import styles from './InputSelect.module.scss';

type SelectProps<T extends boolean, K extends FieldValues> = {
  options: {
    label: string;
    icon?: IconName;
    value: string;
  }[];
  isMulti?: T;
  onlyIconValues?: boolean;
  hideErrorMessage?: boolean;
  onChange?: (value: string | string[] | null) => void;
  isClearable?: boolean;
  control?: Control<K>;
} & Omit<InputProps<K>, 'onChange' | 'control'> &
  Omit<Partial<ReactSelectInputProps>, 'onChange' | 'control'>;
interface Option {
  label: string;
  icon?: IconName;
  value: string;
}

const customInput = (props: ReactSelectInputProps<Option>) => (
  <components.Input
    className={classNames(styles.tbInputSelectInput, {
      [styles.tbInputSelectInputHidden]: !props.selectProps.menuIsOpen
    })}
    {...props}
  />
);

const customPlaceholder = (props: PlaceholderProps<Option>) => (
  <components.Placeholder className={styles.tbInputSelectPlaceholder} {...props} />
);

const customDropdownIndicatorContainer = (props: IndicatorsContainerProps<Option>) => (
  <components.IndicatorsContainer className={styles.tbInputSelectIndicatorsContainer} {...props} />
);

const customDropdownIndicator = () => (
  <div data-testid="tb-input-select-arrow-indicator" className={styles.tbInputSelectArrowIndicator}>
    <Icon name="ArrowDown" />
  </div>
);
const customClearIndicator = (props: ClearIndicatorProps<Option>) => (
  <components.ClearIndicator {...props}>
    <Icon name="X" />
  </components.ClearIndicator>
);
const customIndicatorSeparator = () => <div className={styles.tbInputSelectIndicatorSeparator} />;

const customOption = (props: OptionProps<Option>) => (
  <components.Option {...props} className={styles.tbInputSelectOption}>
    {props.data.icon && <Icon name={props.data.icon} />}
    {props.data.label}
  </components.Option>
);

const customValueContainer = (props: ValueContainerProps<{ icon?: IconName; value: string; label: string }>) => {
  if (props.isMulti) {
    const options = (props.children as React.ReactNode[])[0] as React.ReactNode[];
    const optionsTotal = options?.length ?? 0;
    return (
      <components.ValueContainer className={styles.tbInputSelectValueContainerMulti} {...props}>
        {optionsTotal > 0 && !props.selectProps.menuIsOpen && (
          <span>{`${optionsTotal} ${tryPluralizeWord('option', optionsTotal)} selected`}</span>
        )}
        {props.children}
      </components.ValueContainer>
    );
  }
  const currentValue = props.getValue();
  const optionIcon = currentValue?.[0]?.icon ?? null;

  return (
    <components.ValueContainer {...props} className={styles.tbInputSelectValueContainerSingle}>
      {optionIcon && !!(props.children as React.ReactNode[])?.[0] && <Icon name={optionIcon} />}
      {props.children}
    </components.ValueContainer>
  );
};

const customMenu = (props: MenuProps<Option>) => (
  <components.Menu {...props} className={styles.tbInputSelectMenu}>
    {props.children}
  </components.Menu>
);

function InputSelect<T extends boolean, K extends FieldValues>({
  placeholder,
  label,
  validationRules,
  fieldName,
  readOnly,
  error,
  options,
  control,
  isMulti,
  icon,
  onChange,
  onlyIconValues,
  hideErrorMessage,
  isClearable = true,
  ...restProps
}: SelectProps<T, K>) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const customControlWithIcon = useCallback(
    (props: ControlProps<Option>) => (
      <components.Control {...props} className={styles.tbInputSelectControl}>
        {icon && (
          <div className={styles.tbInputSelectControlIconWrapper}>
            <Icon name={icon} />
            <div className={styles.tbInputSelectControlIconWrapperSeparator} />
          </div>
        )}
        {props.children}
      </components.Control>
    ),
    [icon]
  );

  return (
    <div
      className={classNames({
        [inputStyles.tbInputReadOnly]: readOnly
      })}
      {...restProps}
    >
      {label && (
        <label htmlFor={fieldName} className={inputStyles.tbInputLabel}>
          {label}
        </label>
      )}
      <Controller
        name={fieldName as Path<K>}
        control={control}
        rules={validationRules}
        render={({ field }) => (
          <ReactSelect
            hideSelectedOptions={false}
            closeMenuOnSelect={!isMulti}
            blurInputOnSelect={false}
            isClearable={isClearable}
            className={classNames(styles.tbInputSelect, {
              [styles.tbInputSelectOpen]: isMenuOpen,
              [styles.tbInputSelectHasError]: !!error
            })}
            {...field}
            value={
              isMulti
                ? options.filter((option) => (field.value as string | undefined)?.includes(option.value))
                : options.find((option) => option.value === field.value)
            }
            onChange={(selectedOptions: OnChangeValue<Option, T>) => {
              if (selectedOptions && 'value' in selectedOptions) {
                if (onChange) {
                  onChange(selectedOptions.value);
                }

                return field.onChange(selectedOptions.value);
              }

              let selectedValues = selectedOptions
                ? selectedOptions.map((selectedOption) => selectedOption.value)
                : null;
              if (selectedValues?.length === 0) {
                selectedValues = null;
              }
              if (onChange) {
                onChange(selectedValues);
              }
              return field.onChange(selectedValues);
            }}
            classNames={{
              option: ({ isSelected }) => {
                if (isSelected) {
                  return styles.tbInputSelectOptionSelected;
                }
                return '';
              },
              control: ({ isDisabled }) => {
                if (isDisabled) {
                  return styles.tbInputSelectControlDisabled;
                }
                return '';
              }
            }}
            options={options}
            isDisabled={readOnly}
            placeholder={placeholder}
            instanceId={fieldName}
            components={{
              Placeholder: customPlaceholder,
              DropdownIndicator: customDropdownIndicator,
              Option: customOption,
              Control: customControlWithIcon,
              ValueContainer: customValueContainer,
              Menu: customMenu,
              ClearIndicator: customClearIndicator,
              IndicatorsContainer: customDropdownIndicatorContainer,
              IndicatorSeparator: customIndicatorSeparator,
              Input: customInput
            }}
            onMenuClose={() => setIsMenuOpen(false)}
            onMenuOpen={() => setIsMenuOpen(true)}
            isMulti={isMulti}
          />
        )}
      />
      {error && !hideErrorMessage && <div className={inputStyles.tbInputError}>{error}</div>}
    </div>
  );
}

export default InputSelect;
