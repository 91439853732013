import classNames from 'classnames';
import React from 'react';
import Icon, { IconName } from '../Icon/Icon';
import styles from './Bubble.module.scss';

interface BubbleProps {
  header: string | React.ReactNode;
  icon?: IconName;
  mode?: 'info' | 'danger' | 'validation';
  fill?: boolean;
  body?: string | React.ReactNode;
  className?: string;
}

function Bubble({ header, body, icon, mode = 'info', fill, className, ...props }: BubbleProps) {
  return (
    <div
      className={classNames(
        className,
        styles.tbBubble,
        styles[`tbBubble${mode.charAt(0).toUpperCase() + mode.slice(1)}`],
        {
          [styles.tbBubbleFixed]: !fill,
          [styles.tbBubbleFill]: fill
        }
      )}
      {...props}
    >
      <div className={styles.tbBubbleHeader}>
        {icon && <Icon name={icon} />}
        {header}
      </div>
      {body && header && <div className={styles.tbBubbleBody}>{body}</div>}
    </div>
  );
}

export default Bubble;
