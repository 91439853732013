import classNames from 'classnames';
import React from 'react';
import { FieldValues, Path } from 'react-hook-form';
import Icon from '../../Icon/Icon';
import styles from '../Inputs.module.scss';
import { InputProps } from '../input.types';

function InputText<T extends FieldValues>({
  placeholder,
  label,
  validationRules,
  register,
  fieldName,
  readOnly,
  icon,
  error,
  hasRemoveButton,
  onClickRemove,
  onFocus,
  defaultValue,
  hideErrorMessage,
  ...props
}: InputProps<T>) {
  const registerData = register?.(fieldName as Path<T>, {
    ...validationRules,
    setValueAs: validationRules?.setValueAs
      ? validationRules.setValueAs
      : (value: unknown) => {
          if ((typeof value === 'string' && value.length === 0) || Number.isNaN(value)) {
            return undefined;
          }
          return value;
        }
  });
  return (
    <div
      className={classNames({
        [styles.tbInputReadOnly]: readOnly,
        [styles.tbInputHasError]: !!error
      })}
    >
      {label && (
        <label htmlFor={fieldName} className={styles.tbInputLabel}>
          {label}
        </label>
      )}
      <div
        className={classNames(styles.tbInputWrapper, {
          [styles.tbInputWithRemoveButton]: hasRemoveButton
        })}
      >
        {icon && (
          <>
            <Icon name={icon} />
            <div className={styles.tbInputWrapperSeparator} />
          </>
        )}
        <input
          id={fieldName}
          type="text"
          defaultValue={defaultValue}
          placeholder={placeholder}
          readOnly={readOnly}
          onFocus={onFocus}
          {...registerData}
          {...props}
        />
        {hasRemoveButton && (
          <>
            <div
              className={classNames(styles.tbInputWrapperSeparator, {
                [styles.tbInputSeparatorRemoveButton]: hasRemoveButton
              })}
            />
            <button
              type="button"
              onClick={onClickRemove}
              className={classNames(styles.tbInputRemoveButton, 'input-remove-button')}
              disabled={readOnly}
            >
              <Icon name="X" />
            </button>
          </>
        )}
      </div>
      {error && !hideErrorMessage && (
        <div data-testid="input-error" className={styles.tbInputError}>
          {error}
        </div>
      )}
    </div>
  );
}

export default InputText;
