import classNames from 'classnames';
import React, { FormEvent } from 'react';
import styles from './Form.module.scss';

interface FormProps {
  children: React.ReactNode;
  onSubmit?: (e: FormEvent) => void;
  className?: string;
}

function Form({ children, onSubmit, className, ...props }: FormProps) {
  return (
    <form {...props} className={classNames([styles.tbForm], className)} onSubmit={onSubmit}>
      {children}
    </form>
  );
}

export default Form;
